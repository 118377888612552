<script setup>
/*
text-xs = 12px
text-sm = 14px
text-base = 16px
text-lg = 18px
text-xl = 20px

'xs': '360px',
'sm': '640px',
'md': '768px',
'lg': '1024px',
'xl': '1280px',
'2xl': '1536px',

text-sm sm:text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-xl
*/

// DEVELOPMENT: viewport helper
const viewportWidth = ref(window.innerWidth)
const updateWidth = () => {
  viewportWidth.value = window.innerWidth
}

const tailwindSize = computed(() => {
  const width = viewportWidth.value
  if (width >= 1536) { return "2XL" }
  else if (width >= 1280) { return "XL" }
  else if (width >= 1024) { return "LG" }
  else if (width >= 768) { return "MD" }
  else if (width >= 640) { return "SM" }
  else { return "XS" }
})

// Initialize the helper visibility to false
const showViewportHelper = ref(false)

onMounted(async () => {
  // Set visibility based on domain after mounted
  showViewportHelper.value = window.location.hostname !== "client.transglobalinsurance.ca"
  window.addEventListener('resize', updateWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateWidth)
})
</script>

<template>
  <!-- dev viewport helper -->
  <div v-if="showViewportHelper" class="print-hidden fixed top-0 left-0 px-2 bg-yellow-500 bg-opacity-40">
    {{ tailwindSize }} ({{ viewportWidth }}px)
  </div>
</template>
